@media screen and (max-width: 430px) {
  #features {
    padding: 20px;
    padding-top: 100px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }
  #header .intro-text{
padding-top: 180px;
padding-bottom: 180px;
  }
  #portfolio {
    width: 110%;
  }
}
